/* eslint-disable react/prop-types */
// importSource //
import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import firebase from "gatsby-plugin-firebase";
import tw, { css } from "twin.macro";
import { useAuth } from "~hooks";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: `#32325d`,
      fontFamily: `"Helvetica Neue", Helvetica, sans-serif`,
      fontSmoothing: `antialiased`,
      fontSize: `16px`,
      "::placeholder": {
        color: `#aab7c4`
      }
    },
    invalid: {
      color: `#fa755a`,
      iconColor: `#fa755a`
    }
  },
  hidePostalCode: true
};

const StripeConfirmPayment = ({
  accountId,
  buttonText = `Save`,
  onComplete
}) => {
  // ==========================================================================
  // context / ref / state

  const elements = useElements();
  const stripe = useStripe();

  const { updateAccountById } = useAuth();

  const [status, setStatus] = useState({
    processing: false,
    success: false
  });

  // ==========================================================================
  // methods

  const savePaymentMethod = async (e) => {
    e.preventDefault();

    const resetStatus = () => {
      setStatus({
        processing: false,
        success: false
      });
    };

    if (!accountId || !elements || !stripe) {
      return;
    }

    const hasError = false;

    let paymentMethodId = ``;

    const cardElement = elements.getElement(CardElement);

    setStatus({
      processing: true,
      success: false
    });

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: `card`,
      card: cardElement
    });

    if (error || !paymentMethod?.id || paymentMethod?.id === ``) {
      resetStatus();

      return;
    }

    paymentMethodId = paymentMethod.id;

    if (!paymentMethodId || paymentMethodId === ``) {
      console.error(`Empty payment method ID`);

      resetStatus();

      return;
    }

    if (!hasError) {
      const updatePaymentMethod = firebase
        .functions()
        .httpsCallable(`updatePaymentMethod`);

      updatePaymentMethod({
        accountId,
        paymentMethodId
      })
        .then(async (res) => {
          const stripePaymentMethodId = res?.data?.id;

          console.log(`PAYMENT METHOD: `, res.data);

          if (res?.data?.card?.three_d_secure_usage?.supported) {
            console.log(`3D SECURE`);

            const { error, paymentIntent } = await stripe.confirmCardPayment(
              ``,
              {
                payment_method: {
                  card: res.data.card,
                  billing_details: {
                    name: res?.data?.card?.name || ``
                  }
                }
              }
            );

            if (error) {
              console.log(error);
            } else {
              console.log(paymentIntent);
            }
          }

          updateAccountById(accountId, {
            paymentMethodId: stripePaymentMethodId
          });

          setStatus({
            processing: false,
            success: true
          });

          if (typeof onComplete === `function`) {
            setTimeout(() => {
              onComplete(stripePaymentMethodId);

              resetStatus();
            }, 500);
          }
        })
        .catch((err) => {
          resetStatus();

          // eslint-disable-next-line no-console
          console.error(err);
        });
    } else {
      setStatus({
        processing: false
      });
    }
  };

  // ==========================================================================
  // lifecycle

  // useEffect(async () => {
  //   if (!elements || !verificationActive) {
  //     return;
  //   }

  //   console.log(`stripe.confirmPayment: `, elements);

  //   const { error, paymentIntent } = await stripe.confirmPayment({ elements });

  //   // const { error, paymentIntent } = await stripe.confirmPayment(
  //   //   stripeSubscription.payment_intent_client_secret,
  //   //   {
  //   //     type: "Card"
  //   //   }
  //   // );

  //   if (error) {
  //     console.error(`error: `, error);
  //     return;
  //   }

  //   // todo: scan for errors
  //   console.log(`[debug] paymentIntent: `, paymentIntent);
  // }, [elements, verificationActive]);

  //

  return (
    <>
      <CardElement options={CARD_ELEMENT_OPTIONS} />

      <button
        type="button"
        css={[
          css`
            ${(status?.processing || status?.success) &&
            `opacity: 0.5; pointer-events: none;`}
            border-radius: 8px;
          `,
          tw`h-9 relative block px-3 mt-6 bg-green-dark text-white`
        ]}
        onClick={(e) => {
          if (!status?.processing && !status?.success) {
            savePaymentMethod(e);
          }
        }}
      >
        <span css={[tw`mt-1 block font-body md:text-b2-md text-b2`]}>
          {buttonText}
        </span>
      </button>
    </>
  );
};

export default StripeConfirmPayment;
