/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
// importSource //

import React from "react";

import tw, { css, theme } from "twin.macro";
import { useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

const Button = ({
  _css = [],
  buttonType = `button`,
  className = ``,
  color,
  italic,
  noborder,
  onClick,
  spanStyle = {},
  text
}) => {
  const { colourTransitionCSS } = useTheme();

  let parsedColor = color;

  if (color?.startsWith(`#`)) {
    let matched = false;

    Object.keys(theme`colors`).forEach((colorKey) => {
      if (matched) {
        return;
      }

      const themeColor = theme`colors`[colorKey];

      if (themeColor === color) {
        parsedColor = colorKey;
        matched = true;
      }
    });
  }

  let backgroundColor = `white`;
  let borderColor = `white`;
  let fontColor = `black`;

  switch (parsedColor) {
    case `green-light`:
      backgroundColor = getColor(`green-light`);
      borderColor = getColor(`green-light`);
      fontColor = getColor(`green-dark`);

      break;

    case `green-mid`:
      backgroundColor = getColor(`green-mid`);
      borderColor = getColor(`green-mid`);
      fontColor = getColor(`green-light`);

      break;

    case `green-dark`:
      backgroundColor = getColor(`green-dark`);
      borderColor = getColor(`green-light`);
      fontColor = getColor(`green-light`);

      break;

    case `charcoal`:
      backgroundColor = getColor(`charcoal`);
      borderColor = getColor(`wheat`);
      fontColor = getColor(`wheat`);

      break;

    case `black`:
      backgroundColor = getColor(`charcoal`);
      borderColor = getColor(`charcoal`);
      fontColor = getColor(`wheat`);

      break;

    case `wheat`:
      backgroundColor = getColor(`wheat`);
      borderColor = getColor(`wheat`);
      fontColor = getColor(`charcoal`);

      break;

    default:
      break;
  }

  if (noborder) {
    borderColor = `transparent`;
  }

  let textJSX = text;

  if (italic) {
    textJSX = <em>{text}</em>;
  }

  return (
    <button
      type={buttonType}
      className={className}
      css={[
        css`
          transition: 0.3s ease opacity;

          padding: 0.6vw 0 0.6vw;
          background-color: ${backgroundColor};
          border: 1px solid ${borderColor};
          color: ${fontColor};

          ${MEDIA_QUERIES.hoverable} {
            &:hover {
              opacity: 0.4;
            }
          }
        `,
        ..._css,
        tw`relative block overflow-hidden rounded-lg`
      ]}
      onClick={onClick}
    >
      <span style={spanStyle} className="font-body text-button">
        {textJSX}
      </span>
    </button>
  );
};

export default Button;
