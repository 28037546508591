/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { Button, Grid } from "~components";
import { useTheme } from "~hooks";
import { Logomark } from "~components/_common/Logos/index.jsx";

import logo from "~assets/icons/huge-logo.svg";

const AccountSlideText = ({ status }) => {
  const { colours, lowImpactMode } = useTheme();

  let heading = `Creating account`;
  let message = `Please wait...`;

  if (status?.error) {
    heading = `Error`;
    message = status.error;
  }

  return (
    <Grid _css={[tw`h-full items-center z-10`]}>
      <div
        css={[
          css`
            @keyframes appear {
              0% {
                transform: translate3d(0, 1rem, 0);
                opacity: 0;
              }
              100% {
                transform: translate3d(0, 0, 0);
                opacity: 1;
              }
            }
            grid-column: span 6 / span 6;
            grid-column-start: 4;
            color: ${colours.foreground};
          `,
          tw`relative rounded-xl text-wheat text-center`
        ]}
      >
        <h3
          css={[
            css`
              animation: 1s appear ease forwards;
              transform: translate3d(0, 1rem, 0);
              opacity: 0;
            `,
            tw`font-head text-h2 md:text-h2-md`
          ]}
        >
          {heading}
        </h3>

        <p
          css={[
            css`
              animation: 1s appear ease forwards;
              transform: translate3d(0, 1rem, 0);
              opacity: 0;
              animation-delay: 150ms;
            `,
            tw`mt-4 font-body text-b1 md:text-b1-md`
          ]}
        >
          {message}
        </p>

        <div
          css={[
            css`
              @keyframes load-fader {
                0% {
                  opacity: 1;
                }
                50% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            `,
            tw`relative flex items-center justify-center`
          ]}
        >
          <Logomark
            _css={[
              css`
                ${!status?.error && `animation: 5s load-fader ease infinite;`}
              `,
              tw`w-12 relative block mt-6`
            ]}
            color={lowImpactMode ? colours.background : colours.foreground}
          />
        </div>
      </div>
    </Grid>
  );
};

const AccountSlide = ({ activeStep, stepIndex, status }) => {
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  stepIndex = parseInt(stepIndex);

  //

  return (
    <div
      css={[
        css`
          transition: 0.3s ease transform;

          transform: translate3d(-${activeStep * 100}%, 0, 0);

          flex: 0 0 auto;
        `,
        tw`w-screen h-full relative`
      ]}
    >
      <div
        css={[
          css`
            transition: 0.15s ease background;
            background: ${lowImpactMode
              ? colours?.foreground
              : colours?.background};
            color: ${lowImpactMode ? colours?.background : colours?.foreground};
          `,
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10`
        ]}
      >
        <img
          css={[tw`w-full h-full relative block object-cover`]}
          src={logo}
          alt="Huge logo"
        />
      </div>

      {activeStep.toString() === stepIndex.toString() && (
        <AccountSlideText colours={colours} status={status} />
      )}
    </div>
  );
};

export default AccountSlide;
