/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { capitalizeString } from "~utils/helpers";

/** ============================================================================
 * @component
 * Core manifest of available sections inherited from Sanity.
 */
const PageBuilder = ({ content, sections: reactComponents }) => {
  const { sections } = content || [];

  if (!sections?.[0]) {
    return <></>;
  }

  const jsx = [];

  sections.forEach((section, sectionIndex) => {
    const sectionKey = `sanity-pagebuilder-section-${sectionIndex}`;
    const Section = reactComponents?.[`${capitalizeString(section?._type)}`];

    jsx.push(
      <React.Fragment key={sectionKey}>
        {Section && <Section data={section} />}
      </React.Fragment>
    );
  });

  return jsx;
};

export default PageBuilder;

export const query = graphql`
  fragment CategoryBuilderFragment on SanityCategory {
    pagebuilder {
      sections {
        ...ImageArticleFragment
        ...RichTextFragment
      }
    }
  }

  fragment PageBuilderFragment on SanityPage {
    pagebuilder {
      sections {
        ...ImageArticleFragment
        ...RichTextFragment
      }
    }
  }
`;
