/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { SiteNavigation } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Footer = () => (
  <footer
    css={[
      css`
        height: calc(100vh - 56px);

        ${MEDIA_QUERIES?.desktop} {
          height: calc(100vh - 90px);
          min-height: 768px;
        }
      `,
      tw`w-full relative`
    ]}
  >
    <SiteNavigation fill />
  </footer>
);

export default Footer;
