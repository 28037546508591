/* eslint-disable react/prop-types */
// importSource //

import React from "react";

import tw, { css } from "twin.macro";
import { useApp, useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

const Impactor = () => {
  const { newsletterActive, setNewsletterActive } = useApp();
  const { colours, colourTransitionCSS, lowImpactMode, setLowImpactMode } =
    useTheme();

  //

  return (
    <div
      css={[
        css`
          margin-right: 1rem;

          ${MEDIA_QUERIES.desktop} {
            margin-right: 4%;
          }
        `,
        tw`fixed bottom-0 right-0 z-50 flex flex-col items-end mb-12 pointer-events-none`
      ]}
    >
      <button
        type="button"
        css={[
          css`
            ${colourTransitionCSS};

            padding: 0.25rem 0.5rem;
            margin-bottom: 0.25rem;

            background: ${colours.background};
            color: ${colours.foreground};

            ${MEDIA_QUERIES.desktop} {
              padding: 0.25vw 0.5vw;
              margin-bottom: 0.25vw;
            }
          `,
          tw`flex items-center pointer-events-auto border rounded-md`
        ]}
        onClick={() => setNewsletterActive(!newsletterActive)}
      >
        <p
          css={[
            css`
              margin-top: 0.125rem;

              ${MEDIA_QUERIES.desktop} {
                margin-top: 0.125vw;
              }
            `,
            tw`font-body text-b3 md:text-b3-md`
          ]}
        >
          {newsletterActive ? `Go back` : `Subscribe`}
        </p>
      </button>

      <button
        type="button"
        css={[
          css`
            ${colourTransitionCSS};

            background: ${colours.background};
            color: ${colours.foreground};

            padding: 0.25rem 0.5rem;

            ${MEDIA_QUERIES.desktop} {
              padding: 0.25vw 0.5vw;
            }
          `,
          tw`flex items-center pointer-events-auto border rounded-md`
        ]}
        onClick={() => setLowImpactMode(!lowImpactMode)}
      >
        <p
          css={[
            css`
              margin-top: 0.125rem;

              ${MEDIA_QUERIES.desktop} {
                margin-top: 0.125vw;
              }
            `,
            tw`font-body text-b3 md:text-b3-md`
          ]}
        >
          Lower impact mode
        </p>

        <div
          css={[
            css`
              width: 2rem;
              height: 1rem;
              margin-left: 0.5rem;

              ${MEDIA_QUERIES.desktop} {
                width: 2vw;
                height: 1vw;
                margin-left: 1vw;
              }
            `,
            tw`relative block rounded-full overflow-hidden border`
          ]}
        >
          <div
            css={[
              css`
                transition: 0.15s ease background, 0.15s ease transform;

                transform: translate3d(${lowImpactMode ? `100%` : `0`}, 0, 0);

                width: 1rem;
                height: 1rem;
                top: -1px;
                left: -1px;
                background: ${colours.background};

                ${MEDIA_QUERIES.desktop} {
                  width: 1vw;
                  height: 1vw;
                }
              `,
              tw`relative block rounded-full border`
            ]}
          />
        </div>
      </button>
    </div>
  );
};

export default Impactor;
