/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { Grid } from "~components";

const RegisterSlide = ({ activeStep, children, description, stepIndex }) => {
  stepIndex = parseInt(stepIndex);

  // This slide has been modified to disable sign ups
  return (
    <div
      css={[
        css`
          transition: 0.3s ease transform;

          transform: translate3d(-${activeStep * 100}%, 0, 0);

          flex: 0 0 auto;
          overflow: ${activeStep < 3 ? `auto` : `hidden`};
        `,
        tw`w-screen h-full relative pt-12 md:pt-24`
      ]}
    >
      <Grid>
        <header
          css={[
            css`
              //
            `,
            tw`col-span-12 md:col-span-8 md:col-start-3 md:h-1/2 md:sticky md:top-0 md:left-0`
            // tw`col-span-12 md:col-span-4 md:h-1/2 md:sticky md:top-0 md:left-0`
          ]}
        >
          <h2 css={[tw`font-inline text-h4 md:text-h4-md uppercase`]}>
          {/* <h2 css={[tw`font-inline text-h4 md:text-h4-md uppercase`]}> */}
            Membership is currently closed.{/*Step {stepIndex + 1}*/}
          </h2>

          <p css={[tw`mt-6 font-body text-h4 md:text-h4-md`]}>{description}</p>
        </header>
        
        {/* <div
          css={[
            css`
              //
            `,
            tw`col-span-12 md:col-span-6 md:col-start-6 md:h-full relative mt-2 pt-12 pb-24 md:pb-64`
          ]}
        >
          {children}
        </div> */}
      </Grid>
    </div>
  );
};

export default RegisterSlide;
