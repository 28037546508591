/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { graphql, useStaticQuery } from "gatsby";
import { Button, Go } from "~components";
import { useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

const Topic = ({ colour, text }) => (
  <div
    css={[
      css`
        flex: 0 1 auto;
        color: ${colour};

        ${MEDIA_QUERIES.hoverable} {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      `,
      tw`relative mr-12 flex items-center justify-center`
    ]}
  >
    <div
      css={[
        css`
          background: ${colour};
        `,
        tw`w-6 h-6 relative block mr-6 rounded-full overflow-hidden`
      ]}
    />

    <h3 css={[tw`relative block font-head text-h3 md:text-h3-md`]}>{text}</h3>
  </div>
);

const TopicMarquee = () => {
  const { allSanityTopic } = useStaticQuery(graphql`
    query TopicMarquee {
      allSanityTopic {
        edges {
          node {
            name
            url
          }
        }
      }
    }
  `);

  const topics = allSanityTopic.edges.map(({ node }) => node);

  if (!topics?.[0]) {
    return <></>;
  }

  // ==========================================================================
  // context / ref / state

  const { colours, colourTransitionCSS } = useTheme();

  // ==========================================================================
  // render

  const getTopicJSX = (key) => {
    const wrapperKey = `topic-wrap-${key}`;

    return (
      <div
        key={wrapperKey}
        className="topic-list"
        css={[
          css`
            animation: 20s marquee linear infinite;
            transform: translate3d(50%, 0, 0);

            min-width: 100vw;
            flex: 1 0 auto;
          `,
          tw`relative flex items-center justify-center flex-nowrap whitespace-nowrap`
        ]}
      >
        {topics.map((topic) => {
          const key = `topic-marquee-${topic?.name}`;
          const innerJSX = (
            <Topic colour={colours.secondary} text={topic?.name} />
          );

          return (
            <>
              {(topic?.url && (
                <Go to={topic.url} key={key} newTab>
                  {innerJSX}
                </Go>
              )) || <span key={key}>{innerJSX}</span>}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <section
      css={[
        css`
          ${colourTransitionCSS};
          color: ${colours.secondary};

          @keyframes marquee {
            0% {
              transform: translate3d(50%, 0, 0);
            }
            100% {
              transform: translate3d(-50%, 0, 0);
            }
          }
        `,
        tw`w-full relative block pt-24 md:pt-16 pb-16 overflow-x-hidden`
      ]}
    >
      <h2 css={[tw`font-inline text-h4 md:text-h4-md text-center uppercase`]}>
        TOPICS BEING DISCUSSED IN OUR COMMUNITY
      </h2>

      <div
        css={[
          css`
            &:hover {
              .topic-list {
                animation-play-state: paused;
              }
            }
          `,
          tw`w-full relative flex items-center justify-center flex-nowrap mt-8 md:mt-12 mb-10 md:mb-12`
        ]}
      >
        {Array(4)
          .fill(null)
          .map((_, topicIndex) => {
            const key = `topic-group-${topicIndex}`;

            return <div key={key}>{getTopicJSX(key)}</div>;
          })}
      </div>

      <div
        css={[tw`w-full relative flex items-center justify-center text-center`]}
      >
        <Go
          to="https://app.geneva.com/home/9271a402-c9b8-4c79-a363-73ada19ee131/room/f4fb3202-0dae-4933-af78-17786168b461?inviteId=272987e7-649e-49fe-aff2-4521f886a31e"
          newTab
        >
          <Button
            _css={[tw`h-10 md:h-auto px-8`]}
            color="green-dark"
            text="Join us on Geneva"
          />
        </Go>
      </div>
    </section>
  );
};

export default TopicMarquee;
