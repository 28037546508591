/* eslint-disable react/button-has-type */

import React, { useRef, useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

/** ============================================================================
 * @css
 */

const Container = styled.section`
  transition: 0.3s ease max-height;

  width: 100%;
  max-height: ${({ active, activeHeight }) =>
    active ? `${activeHeight}px` : `4rem`};
  position: relative;
  overflow: hidden;
`;
//

/** ============================================================================
 * @component todo: details/summary tags
 */
const Expander = ({ className = ``, label, content }) => {
  const contentRef = useRef();

  const [active, setActive] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  //

  useEffect(() => {
    if (!contentRef?.current) {
      return;
    }

    setContentHeight(contentRef.current.getBoundingClientRect().height);
  }, [contentRef]);

  //

  return (
    <Container
      className={className}
      active={active}
      activeHeight={contentHeight}
    >
      <button
        type="button"
        onClick={() => {
          setActive(!active);
        }}
        css={css`
          width: 100%;
          height: 4rem;
          position: relative;
          display: block;
          text-align: left;
          text-transform: uppercase;
          border-top: 1px solid var(--color-antiflash);
        `}
      >
        <div
          css={css`
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <span className="b1">{label}</span>
        </div>
      </button>

      <div
        ref={contentRef}
        css={css`
          position: relative;
          padding: 1rem 0 6rem;
        `}
      >
        {content()}
      </div>
    </Container>
  );
};

export default Expander;
