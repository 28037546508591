/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import { Redirect } from "@reach/router";
import tw, { css } from "twin.macro";
import { useApp, useAuth } from "~hooks";
import { LoadingOverlay, LoginForm } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Login = ({ location }) => {
  const { registerActive } = useApp();
  const { initialized, loggedIn } = useAuth();

  if (!initialized) {
    return <LoadingOverlay />;
  }

  if (loggedIn && !registerActive) {
    return <Redirect noThrow to="/app/profile" />;
  }

  //

  return (
    <section
      css={[
        css`
          height: calc(100vh - 56px);

          margin-top: 56px;

          ${MEDIA_QUERIES?.desktop} {
            height: calc(100vh - 90px);
            min-height: 768px;
            margin-top: 90px;
          }
        `,
        tw`w-full relative z-10`
      ]}
    >
      <LoginForm />
    </section>
  );
};

export default Login;
