/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { ArticleCardSmall, Grid } from "~components";
import { useTheme } from "~hooks";

const ArticleGrid = ({ articles, max = -1 }) => {
  // ===========================================================================
  // context / ref / state

  const { colours } = useTheme();

  if (max !== -1 && articles.length > max) {
    articles.length = max;
  }

  // ===========================================================================
  // render

  return (
    <section
      css={[
        css`
          background: ${colours.background};
          color: ${colours.foreground};
        `,
        tw`w-full relative block pt-20 pb-20 bg-grey-tint`
      ]}
    >
      <Grid>
        {(!articles?.[0] && (
          <article
            css={css`
              grid-column: span 12 / span 12;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              color: black;
            `}
          >
            <h1 css={[tw`font-head text-h3-md italic`]}>
              Sample article grid.
            </h1>
          </article>
        )) ||
          articles.map((article) => (
            <div
              key={`article-${
                article?._id || article?.slug?.current || article?.name
              }`}
              css={[tw`col-span-12 md:col-span-3 relative mb-8`]}
            >
              <ArticleCardSmall article={article} />
            </div>
          ))}
      </Grid>
    </section>
  );
};

export default ArticleGrid;
