/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";
import tw, { css } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useTheme } from "~hooks";

const Image = ({
  _css,
  image,
  loading,
  title,
  background,
  foreground,
  fallbackCSS
}) => {
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  if (lowImpactMode) {
    return (
      <div
        css={[
          css`
            background: ${background || colours?.background};
            color: ${foreground || colours?.foreground};
          `,
          tw`w-full h-full relative flex items-center justify-center`
        ]}
      >
        <div
          css={[
            css`
              width: calc(100% - 2rem);

              ${fallbackCSS};
            `,
            tw`relative`
          ]}
        >
          <div
            css={[
              css`
                opacity: 0.05;
              `,
              tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 rounded-xl overflow-hidden bg-black`
            ]}
          />

          <article
            css={[
              css`
                width: 100%;
                padding: 1rem 1.25rem;
              `,
              tw`relative z-10`
            ]}
          >
            <h3 className="font-body text-b2 md:text-b2-md uppercase">
              Image caption
            </h3>
            <p className="mt-2 font-body text-b3 md:text-b3-md">
              {image?.altText || `Image description`}
            </p>
          </article>
        </div>
      </div>
    );
  }

  const imageObj = image?.asset || image;

  if (!imageObj) {
    return <></>;
  }

  const imageData = getImage(imageObj);

  const src = imageData?.images?.fallback?.src;

  //

  return (
    <>
      {(image?.asset?.src?.includes(`.svg`) && (
        <img
          css={_css}
          src={src}
          alt={image?.altText || ``}
          title={title || image?.altText || ``}
        />
      )) || (
        <GatsbyImage
          css={_css}
          loading={loading || `eager`}
          image={imageData}
          alt={image?.altText || ``}
          title={title || image?.altText || ``}
        />
      )}
    </>
  );
};

export default Image;

Image.defaultProps = {
  _css: [],
  alt: null,
  loading: null,
  title: null
};
Image.propTypes = {
  _css: PropTypes.arrayOf(PropTypes.shape({})),
  image: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
    .isRequired,
  alt: PropTypes.string,
  loading: PropTypes.string,
  title: PropTypes.string
};
