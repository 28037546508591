/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
// import { useAuth, useTheme } from "~hooks";
import { useTheme } from "~hooks";
import { Grid } from "~components";
import { Logomark } from "~components/_common/Logos/index.jsx";

import logo from "~assets/icons/huge-logo.svg";

const LoadingOverlay = () => {
  // const { initialized } = useAuth();
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  //

  // opacity: ${!initialized ? `1` : `0`};
  // pointer-events: ${!initialized ? `auto` : `none`};
  return (
    <div
      css={[
        css`
          ${colourTransitionCSS}, 1s ease opacity;

          background: ${colours.background};
          color: ${colours.foreground};
        `,
        tw`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center`
      ]}
    >
      <div
        css={[
          css`
            ${colourTransitionCSS};

            background: ${colours.background};
          `,
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0`
        ]}
      >
        <img
          css={[tw`w-full h-full relative block object-cover`]}
          src={logo}
          alt="Huge logo"
        />
      </div>

      <Grid>
        <article css={[tw`col-span-6 col-start-4 relative z-20 text-center `]}>
          <h2
            css={[
              css`
                @keyframes text-load {
                  0% {
                    transform: translate3d(0, 1rem, 0);
                    opacity: 0;
                  }
                  100% {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                  }
                }

                animation: 1s cubic-bezier(0.215, 0.61, 0.355, 1) text-load
                  forwards;
                animation-delay: 0.25ms;
                transform: translate3d(0, 1rem, 0);
                opacity: 0;
              `,
              tw`font-head text-h2-md`
            ]}
          >
            Welcome to The WIP
          </h2>

          <div
            css={[
              css`
                @keyframes logomark-load {
                  0% {
                    transform: translate3d(0, -1rem, 0);
                    opacity: 0;
                  }
                  100% {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                  }
                }

                @keyframes logomark-fader {
                  0% {
                    opacity: 1;
                  }
                  50% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }

                animation: 1s cubic-bezier(0.215, 0.61, 0.355, 1) logomark-load
                  forwards;
                animation-delay: 0.25ms;

                transform: translate3d(0, -1rem, 0);
                opacity: 0;
              `,
              tw`relative flex items-center justify-center`
            ]}
          >
            <Logomark
              _css={[
                css`
                  animation: 5s logomark-fader ease infinite;
                `,
                tw`w-12 relative block mt-6`
              ]}
              color={colours.foreground}
            />
          </div>
        </article>
      </Grid>
    </div>
  );
};

export default LoadingOverlay;
