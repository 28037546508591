/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useRef, useState } from "react";
import tw, { css } from "twin.macro";
import { useTheme } from "~hooks";

import { ReactComponent as Triangle } from "~assets/icons/triangle.svg";

const JourneySlider = ({ defaultValue, heading, items, onChange }) => {
  // ==========================================================================
  // context / ref / state

  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  const sliderRef = useRef();

  const [activeIndex, setActiveIndex] = useState(0);

  // ==========================================================================
  // parsing

  if (!items?.[0]) {
    return <></>;
  }

  const itemLength = items.length - 1;
  const lineCount = itemLength * 10 + 1;

  const journeyText = [];

  // ==========================================================================
  // lifecycle

  useEffect(() => {
    onChange(`${journeyText[activeIndex]}:${activeIndex}`);
  }, [activeIndex]);

  useEffect(() => {
    if (defaultValue && defaultValue?.includes(`:`)) {
      setActiveIndex(defaultValue.split(`:`)[1]);
    }
  }, [defaultValue]);

  // ==========================================================================
  // render

  Array(lineCount)
    .fill(null)
    .forEach((_, itemIndex) => {
      if (itemIndex === lineCount - 1) {
        journeyText.push(items[items.length - 1]);
      } else {
        journeyText.push(
          items[parseInt(((itemIndex + 1) / lineCount) * itemLength)]
        );
      }
    });

  return (
    <div
      css={[
        css`
          //
        `,
        tw`relative block mb-8 pb-16`
      ]}
    >
      <p css={[tw`block mb-4 font-body italic text-b2-md`]}>{heading}</p>

      <div
        ref={sliderRef}
        css={[
          css`
            .slider {
              //
              height: 5vw;
              opacity: 0;
              cursor: pointer;
            }
          `,
          tw`w-full relative block`
        ]}
      >
        <input
          id="register-journey"
          name="journey"
          className="slider"
          css={[
            css`
              //
            `,
            tw`w-full relative block`
          ]}
          min="0"
          max="40"
          onChange={(e) => {
            const { value } = e.currentTarget;

            setActiveIndex(value);
          }}
          type="range"
        />

        <div
          css={[
            tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 pointer-events-none`
          ]}
        >
          <div
            css={[
              css`
                //
              `,
              tw`w-full h-full relative top-0 right-0 bottom-0 left-0 flex items-start justify-between`
            ]}
          >
            {Array(lineCount)
              .fill(null)
              .map((_, lineIndex) => {
                const lineKey = `line-${lineIndex}`;
                const isLong = lineIndex === 0 || lineIndex % 10 === 0;

                return (
                  <div
                    key={lineKey}
                    css={[
                      css`
                        width: 1px;
                        height: ${isLong ? `60` : `40`}%;
                        position: relative;
                        display: block;
                        background: ${lowImpactMode
                          ? colours.foreground
                          : colours.background};
                      `
                    ]}
                  />
                );
              })}
          </div>

          <div
            css={[
              css`
                transform: translate3d(${activeIndex * 83.25}%, 0, 0);

                width: 3%;
                position: absolute;
                bottom: 0;
                left: -1.5%;
              `
            ]}
          >
            <Triangle
              css={[
                css`
                  width: 100%;
                `
              ]}
              fill={lowImpactMode ? colours.foreground : colours.background}
            />

            <div
              css={[
                css`
                  transform: translate3d(-50%, 110%, 0);

                  min-width: 10rem;
                  bottom: 0;
                  left: 50%;
                `,
                tw`absolute`
              ]}
            >
              <p css={[tw`font-body text-center text-b3 md:text-b3-md`]}>
                {journeyText[activeIndex]}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneySlider;
