/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useRef, useState } from "react";

import { PropTypes } from "prop-types";
import tw from "twin.macro";
import { useEmail } from "~hooks";
import { validateEmail } from "~utils/helpers";

const KlaviyoForm = ({ listId, render }) => {
  // ===========================================================================
  // context / ref / state

  const { klaviyoSubscribe } = useEmail();

  const [form, setForm] = useState({
    email: ``
  });
  const [focused, setFocused] = useState(null);
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false
  });
  const [valid, setValid] = useState(null);

  // ===========================================================================
  // methods

  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      status?.submitting ||
      status?.submitted ||
      form?.email === null ||
      form?.email === `` ||
      !valid
    ) {
      return false;
    }

    setStatus({
      submitted: false,
      submitting: true
    });

    const klaviyoSubscription = await klaviyoSubscribe({
      listId,
      email: form.email
    });

    setStatus({
      submitted: true,
      submitting: false
    });

    return false;
  };

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    setValid(form?.email && validateEmail(form.email));
  }, [form]);

  // ===========================================================================
  // lifecycle

  return (
    <>
      {listId && (
        <form css={[tw`w-full relative block`]} onSubmit={onSubmit}>
          {render({
            data: form,
            focused,
            setFocused,
            status,
            update: setForm,
            valid
          })}
        </form>
      )}
    </>
  );
};

KlaviyoForm.defaultProps = {
  debug: false
};

KlaviyoForm.propTypes = {
  debug: PropTypes.bool,
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
};

export default KlaviyoForm;
