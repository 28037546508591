/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React from "react";
import { Redirect } from "@reach/router";
import { useAuth } from "~hooks";
import { LoadingOverlay } from "~components";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { initialized, loggedIn } = useAuth();

  if (!initialized) {
    return <LoadingOverlay />;
  }

  if (
    !loggedIn &&
    location?.pathname !== `/app/login` &&
    location?.pathname !== `/app/login/`
  ) {
    return <Redirect noThrow to="/app/login" />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
