/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { Go } from "~components";
import { useTheme } from "~hooks";

import { ReactComponent as CategoryCommunity } from "~assets/icons/community-icon.svg";
import { ReactComponent as CategoryCultivate } from "~assets/icons/cultivate-icon.svg";
import { ReactComponent as CategoryGrowth } from "~assets/icons/growth-icon.svg";
import { ReactComponent as CategoryReplenish } from "~assets/icons/replenish-icon.svg";
import { ReactComponent as CategoryTheLand } from "~assets/icons/landscape-icon.svg";
import { ReactComponent as CategoryToolkit } from "~assets/icons/toolkit-icon.svg";

const CategoryCard = ({ category }) => {
  // -------------------------------------------------------------------------
  // context / ref / state

  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  // -------------------------------------------------------------------------
  // variables

  const iconCSS = [tw`w-1/2 relative`];

  const icons = {
    community: (
      <CategoryCommunity
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    ),
    cultivate: (
      <CategoryCultivate
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    ),
    growth: (
      <CategoryGrowth
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    ),
    replenish: (
      <CategoryReplenish
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    ),
    "the land": (
      <CategoryTheLand
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    ),
    toolkit: (
      <CategoryToolkit
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    )
  };

  // -------------------------------------------------------------------------
  // render

  return (
    <article
      css={[
        css`
          ${colourTransitionCSS};

          background: ${lowImpactMode
            ? colours.background
            : colours.foreground};
          color: ${lowImpactMode ? colours.foreground : colours.background};
        `,
        tw`w-full relative block mb-4 rounded-lg overflow-hidden`
      ]}
    >
      <Go to={`/categories/${category?.slug?.current}`}>
        <div css={[tw`w-full relative md:flex md:items-start p-3 md:p-4`]}>
          <div
            css={[
              css`
                ${colourTransitionCSS};

                background: ${lowImpactMode
                  ? colours.foreground
                  : colours.background};
              `,
              tw`w-16 md:w-1/5 relative rounded-lg overflow-hidden`
            ]}
          >
            <div css={[tw`w-full relative block pb-[100%]`]}>
              <div
                css={[
                  tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center`
                ]}
              >
                {icons?.[category?.name?.toLowerCase()]}
              </div>
            </div>
          </div>

          <div css={[tw`w-full md:w-4/5 relative block md:pl-6 rounded-md`]}>
            <h3
              css={[
                tw`mt-4 md:mt-0 mb-1 md:mb-2 italic font-body text-b2 md:text-b2-md`
              ]}
            >
              {category.name}
            </h3>

            {category?.description && (
              <p css={[tw`font-body text-b2 md:text-b2-md`]}>
                {category.description}
              </p>
            )}
          </div>
        </div>
      </Go>
    </article>
  );
};

export default CategoryCard;
