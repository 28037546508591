/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import CookieConsent from "react-cookie-consent";

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I Accept"
      cookieName="gdprCompliance"
      style={{ background: "#1A1A19" }}
      buttonStyle={{
        background: "#F2E6CE",
        color: "#000000",
        fontSize: "14px"
      }}
      expires={150}
    >
      <p css={[tw`block font-body text-button`]}>
        This website uses cookies to enhance the user experience.
      </p>
    </CookieConsent>
  );
};

export default CookieBanner;
