/* eslint-disable react/prop-types */
// importSource //

import React from "react";

import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { MEDIA_QUERIES } from "~utils/css";
import { remToPx } from "~utils/helpers";

export const GRID_COLUMNS = 12;
export const GRID_GAP_REM = 1.25;
export const GRID_GAP_REM_XS = 0.5;
export const GRID_GAP_PX = remToPx(GRID_GAP_REM);
export const GRID_GAP_PX_XS = remToPx(GRID_GAP_REM_XS);
export const GRID_PADDING_REM = 3;
export const GRID_PADDING_REM_XS = 0.75;
export const GRID_PADDING_PX = remToPx(GRID_GAP_REM);

/**
 * -----------------------------------------------------------------------------
 * Receive a CSS grid wrapper to style guide spec.
 * @param  {node}   children  Inner JSX
 * @param  {string} node      Wrapper JSX node type (defaults to <div>)
 * @param  {object} _css      Additional CSS
 * @return {node}             The resulting Grid node
 */
const Grid = ({ _css, children, node }) => {
  const G = `${node}`;

  return (
    <G
      css={css`
        ${_css};

        width: 100%;
        position: relative;
        display: grid;
        grid-template-columns: repeat(${GRID_COLUMNS}, minmax(0, 1fr));
        grid-gap: 0 ${GRID_GAP_REM_XS}rem;
        margin-right: auto;
        margin-left: auto;
        padding-left: ${GRID_PADDING_REM_XS}rem;
        padding-right: ${GRID_PADDING_REM_XS}rem;

        ${MEDIA_QUERIES?.desktop} {
          grid-gap: 0 ${GRID_GAP_REM}rem;
          padding-left: ${GRID_PADDING_REM}rem;
          padding-right: ${GRID_PADDING_REM}rem;
        }
      `}
    >
      {children}
    </G>
  );
};

Grid.defaultProps = {
  _css: [],
  node: `div`
};

Grid.propTypes = {
  _css: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node.isRequired,
  node: PropTypes.string
};

export default Grid;
