/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import { CategoryCard, Grid, SwiperCarousel } from "~components";
import { useTheme } from "~hooks";

const CategoryGrid = ({ categories, heading }) => {
  // -------------------------------------------------------------------------
  // context / ref / state

  const [mode, setMode] = useState(null);
  const { colours, lowImpactMode } = useTheme();

  // -------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined`) {
      return () => {};
    }

    const onResize = () => {
      if (window.innerWidth < 1024) {
        setMode(`carousel`);
      } else {
        setMode(`default`);
      }
    };

    onResize();

    window.addEventListener(`resize`, onResize, false);

    return () => {
      window.removeEventListener(`resize`, onResize, false);
    };
  }, []);

  // -------------------------------------------------------------------------
  // render

  if (mode === null || !categories?.[0]) {
    return <></>;
  }

  return (
    <section
      css={[
        css`
          color: ${lowImpactMode ? colours.foreground : colours.background};
        `,
        tw`w-full relative block pt-16 md:pt-24 pb-6 md:pb-12`
      ]}
    >
      {mode === `default` && (
        <Grid>
          {categories.map((category) => (
            <div
              key={`category-${
                category?._id || category?.slug?.current || category?.name
              }`}
              css={[tw`col-span-4 relative`]}
            >
              <CategoryCard category={category} />
            </div>
          ))}
        </Grid>
      )}

      {mode === `carousel` && (
        <SwiperCarousel
          _css={[
            css`
              width: calc(100% - 2rem);
            `
          ]}
          slides={categories.map((category) => (
            <div
              key={`category-slide-${
                category?._id || category?.slug?.current || category?.name
              }`}
            >
              <CategoryCard category={category} />
            </div>
          ))}
        />
      )}
    </section>
  );
};

export default CategoryGrid;
