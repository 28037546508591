/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useState } from "react";
import firebase from "gatsby-plugin-firebase";
import tw, { css } from "twin.macro";
import { useAuth } from "~hooks";

import { ReactComponent as FounderBadge } from "~assets/icons/founder-badge.svg";

const ProfileIcon = ({ badges, color }) => {
  // ==========================================================================
  // context / ref / state

  const { account } = useAuth();

  const [avatar, setAvatar] = useState();

  const initials = `${account?.firstName?.[0] || `W`}${
    account?.lastName?.[0] || `W`
  }`;

  // ==========================================================================
  // lifecycle

  useEffect(() => {
    if (!account?.avatar || avatar) {
      return;
    }

    const storageRef = firebase.storage().ref();

    storageRef
      .child(account.avatar)
      .getDownloadURL()
      .then((url) => {
        setAvatar(url);
      });
  }, [account]);

  // ==========================================================================
  // render

  if (!account) {
    return null;
  }

  return (
    <div
      css={[
        css`
          color: ${color};
        `,
        tw`w-full h-full relative flex items-center justify-center rounded-full border`
      ]}
    >
      {badges && (
        <>
          {account?.badges?.includes(`founder`) && (
            <div
              css={[
                tw`w-8 h-8 absolute top-0 -right-5 z-10 bg-green-dark rounded-full`
              ]}
            >
              <FounderBadge css={[tw`w-full h-full relative block`]} />
            </div>
          )}
        </>
      )}

      {(avatar && (
        <figure css={[tw`w-full h-full relative rounded-full overflow-hidden`]}>
          <img
            css={[tw`w-full h-full relative block object-cover`]}
            src={avatar}
          />
        </figure>
      )) || (
        <p
          css={[
            css`
              margin-top: 0.25rem;
              font-size: 20px !important;
            `,
            tw`font-body text-b1 md:text-b1-md`
          ]}
        >
          {initials}
        </p>
      )}
    </div>
  );
};

export default ProfileIcon;
