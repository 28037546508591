/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useState } from "react";
import BlockContent from "@sanity/block-content-to-react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { Button, Go, Image } from "~components";

const PortableText = ({ blocks, serializer }) => {
  const sanityConfig = { projectId: `l9quez0h`, dataset: `production` };

  const [serializers] = useState({
    types: {
      block: ({ children, node }) => {
        if (typeof children?.[0] === `undefined`) {
          return <></>;
        }

        switch (node.style) {
          case `h1`:
            return (
              <h2 className="mb-6 font-head text-h1 md:text-h1-md">
                {children}
              </h2>
            );

          case `h2`:
            return (
              <h2 className="mb-6 font-head text-h2 md:text-h2-md">
                {children}
              </h2>
            );

          case `h3`:
            return (
              <h3 className="mb-4 font-head text-h3 md:text-h3-md">
                {children}
              </h3>
            );

          case `h4`:
            return (
              <h4 className="mb-4 font-head text-h4 md:text-h4-md">
                {children}
              </h4>
            );

          case `h5`:
            return (
              <h5 className="mb-4 font-head text-h4 md:text-h4-md">
                {children}
              </h5>
            );

          case `h6`:
            return (
              <h6 className="mb-4 font-head text-h4 md:text-h4-md">
                {children}
              </h6>
            );

          case `normal`:
            return (
              <p className="mt-6 mb-4 font-body text-b1 md:text-b1-md">
                {children}
              </p>
            );

          case `blockquote`:
            return (
              <blockquote>
                <p className="font-body text-b1 md:text-b1-md">
                  <span>|</span>
                  <span className="pl-4">{children}</span>
                </p>
              </blockquote>
            );

          default:
            return <></>;
        }
      },

      altImage: ({ children, node }) => {
        if (typeof children?.[0] === `undefined`) {
          return <></>;
        }

        const imageData = getGatsbyImageData(
          node.asset._ref,
          { maxWidth: 1024 },
          sanityConfig
        );

        const imageJSX = (
          <Image
            className="w-full relative block"
            image={imageData}
            alt={node.altText}
          />
        );

        return (
          <figure className="w-full relative block mt-4 mb-16">
            {imageJSX}
          </figure>
        );
      },

      embed: ({ node }) => {
        if (typeof node?.embed === `undefined`) {
          return <></>;
        }

        return (
          <div
            className="w-full relative block mt-4 mb-16"
            dangerouslySetInnerHTML={{ __html: node?.embed }}
          />
        );
      }
    },

    list: ({ children, type }) => {
      if (typeof children?.[0] === `undefined`) {
        return <></>;
      }

      let innerJSX = (
        <ol className="pl-6 pr-4 md:pr-0 font-body text-b2 md:text-b2-md list-decimal list-outside">
          {children}
        </ol>
      );

      if (type === `bullet`) {
        innerJSX = (
          <ul className="pl-6 pr-4 md:pr-0 font-body text-b2 md:text-b2-md list-disc list-outside">
            {children}
          </ul>
        );
      }

      return (
        <div className="w-full relative mt-4 mb-6 whitespace-pre-wrap">
          {innerJSX}
        </div>
      );
    },
    listItem: ({ children }) => {
      if (typeof children?.[0] === `undefined`) {
        return <></>;
      }

      return (
        <li>
          <p font="2" className="mb-2">
            {children}
          </p>
        </li>
      );
    },

    marks: {
      link: ({ children, mark }) => {
        if (typeof children?.[0] === `undefined`) {
          return <></>;
        }

        if (mark?.newTab) {
          return (
            <a href={mark.href} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          );
        }

        return <a href={mark.href}>{children}</a>;
      },
      button: ({ children, mark }) => {
        if (!children?.[0]) {
          return <></>;
        }

        if (mark.internal) {
          return (
            <Go to={mark.url} className="article-button">
              <Button
                style={{
                  padding: `0 2rem !important`
                }}
                spanStyle={{
                  display: `block`
                }}
                className="w-64 h-12 relative block mt-4 mb-4"
                color="green-dark"
                text={children[0]}
                transparent
              />
            </Go>
          );
        }
        return (
          <a
            href={mark.url}
            rel="noopener noreferrer"
            target="_blank"
            className="article-button"
          >
            <Button
              style={{
                padding: `0 2rem !important`
              }}
              spanStyle={{
                display: `block`
              }}
              className="w-64 h-12 relative block mt-4 mb-4"
              color="green-dark"
              text={children}
              transparent
            />
          </a>
        );
      }
    }
  });

  //

  return (
    <BlockContent blocks={blocks} serializers={serializer || serializers} />
  );
};

export default PortableText;
