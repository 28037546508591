/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { useApp, useTheme } from "~hooks";
import { SiteNavigation } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Menu = () => {
  const { menuActive } = useApp();
  const { colourTransitionCSS } = useTheme();

  // ==========================================================================
  // render

  const transitionDuration = `0.6s`;

  return (
    <div
      css={[
        css`
          ${colourTransitionCSS}, ${transitionDuration} cubic-bezier(0.215, 0.61, 0.355, 1) opacity, ${transitionDuration} cubic-bezier(0.215, 0.61, 0.355, 1) transform;

          opacity: ${menuActive ? `1` : `0`};
          pointer-events: ${menuActive ? `auto` : `none`};
          transform: scale(${menuActive ? `1` : `1.025`});

          padding-top: 56px;

          ${MEDIA_QUERIES?.desktop} {
            padding-top: 90px;
          }
        `,
        tw`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-30`
      ]}
    >
      <SiteNavigation fill inMenu />
    </div>
  );
};

export default Menu;
