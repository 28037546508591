/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import { Grid } from "~components";
import { useTheme } from "~hooks";
import { Logomark } from "~components/_common/Logos/index.jsx";

import logo from "~assets/icons/huge-logo.svg";

const LoadingSlideText = ({ colours, status }) => {
  let heading = `Connecting your account...`;
  let message = `Please wait...`;

  if (status?.error) {
    heading = `Error`;
    message = status.error;
  }

  return (
    <Grid _css={[tw`h-full items-center z-10`]}>
      <div
        css={[
          css`
            @keyframes appear {
              0% {
                transform: translate3d(0, 1rem, 0);
                opacity: 0;
              }
              100% {
                transform: translate3d(0, 0, 0);
                opacity: 1;
              }
            }

            grid-column: span 6 / span 6;
            grid-column-start: 4;
            color: ${colours.foreground};
          `,
          tw`relative rounded-xl text-wheat text-center`
        ]}
      >
        <h3
          css={[
            css`
              animation: 1s appear ease forwards;
              transform: translate3d(0, 1rem, 0);
              opacity: 0;
            `,
            tw`font-head text-h2-md`
          ]}
        >
          {heading}
        </h3>

        <p
          css={[
            css`
              animation: 1s appear ease forwards;
              transform: translate3d(0, 1rem, 0);
              opacity: 0;
              animation-delay: 150ms;
            `,
            tw`mt-4 font-body text-b1-md`
          ]}
        >
          {message}
        </p>

        <div
          css={[
            css`
              @keyframes load-fader {
                0% {
                  opacity: 1;
                }
                50% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            `,
            tw`relative flex items-center justify-center`
          ]}
        >
          <Logomark
            _css={[
              css`
                ${!status?.error && `animation: 5s load-fader ease infinite;`}
              `,
              tw`w-12 relative block mt-6`
            ]}
            color={colours.foreground}
          />
        </div>
      </div>
    </Grid>
  );
};

//

const LoadingSlide = () => {
  const { colours } = useTheme();

  return (
    <div
      css={[
        css`
          flex: 0 0 auto;
        `,
        tw`w-screen h-full absolute top-0 right-0 bottom-0 left-0 z-10`
      ]}
    >
      <div
        css={[
          css`
            transition: 0.15s ease background;
            background: ${colours?.secondary || `black`};
          `,
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10`
        ]}
      >
        <img
          css={[tw`w-full h-full relative block object-cover`]}
          src={logo}
          alt="Huge logo"
        />
      </div>

      <LoadingSlideText colours={colours} />
    </div>
  );
};

export default LoadingSlide;
