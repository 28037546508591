/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { ArticleCard, ArticleCardSmall, Grid, TopicMarquee } from "~components";

const ArticleList = ({ articles, withHeading = false }) => {
  // --------------------------------------------------------------------------
  // context / ref / state

  const [mode, setMode] = useState(null);

  // --------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined`) {
      return () => {};
    }

    const onResize = () => {
      if (window.innerWidth < 1024) {
        setMode(`small`);
      } else {
        setMode(`large`);
      }
    };

    onResize();

    window.addEventListener(`resize`, onResize, false);

    return () => {
      window.removeEventListener(`resize`, onResize, false);
    };
  }, []);

  // --------------------------------------------------------------------------
  // render

  if (!mode || !articles?.[0]) {
    return <></>;
  }

  return (
    <section
      css={[
        withHeading
          ? tw`w-full relative block pt-6 md:pt-24`
          : tw`w-full relative block pt-6`
      ]}
    >
      {withHeading && (
        <Grid>
          <div css={[tw`col-span-12 md:col-span-10 md:col-start-2 relative`]}>
            <h2
              css={[
                tw`mb-4 md:mb-0 font-head text-h3 md:text-h3-md text-charcoal`
              ]}
            >
              Latest posts
            </h2>
          </div>
        </Grid>
      )}

      {articles.map((article, articleIndex) => (
        <div key={`article-list-${article.title}`} css={[tw`mb-12 md:mb-0`]}>
          {mode === `small` && (
            <Grid>
              <div
                css={[tw`col-span-12 md:col-span-10 md:col-start-2 relative`]}
              >
                <ArticleCardSmall article={article} withLink />
              </div>
            </Grid>
          )}

          {mode === `large` && (
            <Grid>
              <div
                css={[tw`col-span-12 md:col-span-10 md:col-start-2 relative`]}
              >
                <ArticleCard article={article} withLink />
              </div>
            </Grid>
          )}

          {(articleIndex + 1) % 3 === 0 && <TopicMarquee />}
        </div>
      ))}
    </section>
  );
};

export default ArticleList;
