/* eslint-disable react/prop-types */
// importSource //

import React from "react";
import tw, { css } from "twin.macro";
import {
  CookieBanner,
  Header,
  Impactor,
  Menu,
  NewsletterOverlay,
  PanicMode,
  Register,
  SEO
} from "~components";
import { useApp } from "~hooks";

const Layout = ({ children, location, seo }) => {
  const { menuActive, registerActive } = useApp();

  return (
    <>
      <Header />

      <Menu />

      <Register />

      <Impactor />

      <PanicMode />

      <CookieBanner />

      <NewsletterOverlay />

      <main
        css={[
          css`
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-smooth: always;

            @keyframes appear {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }

            @keyframes appear-up {
              0% {
                transform: translate3d(0, 1rem, 0);
                opacity: 0;
              }
              100% {
                transform: translate3d(0, 0, 0);
                opacity: 1;
              }
            }

            @keyframes appear-down {
              0% {
                transform: translate3d(0, -1rem, 0);
                opacity: 0;
              }
              100% {
                transform: translate3d(0, 0, 0);
                opacity: 1;
              }
            }

            @keyframes appear-left {
              0% {
                transform: translate3d(1rem, 0, 0);
                opacity: 0;
              }
              100% {
                transform: translate3d(0, 0, 0);
                opacity: 1;
              }
            }

            @keyframes appear-right {
              0% {
                transform: translate3d(-1rem, 0, 0);
                opacity: 0;
              }
              100% {
                transform: translate3d(0, 0, 0);
                opacity: 1;
              }
            }

            @keyframes scale-up {
              0% {
                transform: scale(0.95);
                opacity: 0;
              }
              100% {
                transform: scale(1);
                opacity: 1;
              }
            }

            position: ${registerActive || menuActive ? `fixed` : `relative`};
          `,
          tw`w-full`
        ]}
      >
        <SEO {...seo} location={location} />

        {children}
      </main>
    </>
  );
};

export default Layout;
