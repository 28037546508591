/* eslint-disable react/no-danger */

import React, { useContext } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import tw, { css } from "twin.macro";
import { MEDIA_QUERIES } from "~utils/css";

const PanicMode = () => {
  const { sanityGlobals } = useStaticQuery(graphql`
    query PanicMode {
      sanityGlobals {
        panicOverlay
      }
    }
  `);

  if (!sanityGlobals?.panicOverlay || sanityGlobals.panicOverlay === ``) {
    return <></>;
  }

  return (
    <div
      css={[
        css`
          z-index: 9999;
        `,
        tw`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center bg-green-dark`
      ]}
    >
      <div
        css={[
          css`
            width: calc(100% - 2rem);
            margin: 0 auto;

            ${MEDIA_QUERIES.desktop} {
              width: 720px;
            }
          `,
          tw`p-4 bg-green-light text-green-dark rounded-xl`
        ]}
      >
        <p css={[tw`text-b1 md:text-b1-md`]}>
          <span
            dangerouslySetInnerHTML={{
              __html: sanityGlobals?.panicOverlay
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default PanicMode;
