/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useRef, useState } from "react";
import tw, { css } from "twin.macro";
import { Grid } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

/** ============================================================================
 * @component todo: details/summary tags
 */
const FAQExpander = ({ className = ``, label, content }) => {
  const contentRef = useRef();
  const labelRef = useRef();

  const [active, setActive] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [labelHeight, setLabelHeight] = useState(0);

  //

  useEffect(() => {
    if (!contentRef?.current) {
      return;
    }

    const { height } = contentRef.current.getBoundingClientRect();

    setContentHeight(height);
  }, [contentRef]);

  useEffect(() => {
    if (!labelRef?.current) {
      return;
    }

    const { height } = labelRef.current.getBoundingClientRect();

    setLabelHeight(height);
  }, [labelRef]);

  //

  return (
    <div
      className={className}
      active={active}
      activeHeight={contentHeight}
      css={[
        css`
          transition: 0.3s ease max-height;

          width: 100%;
          max-height: ${active
            ? `calc(${contentHeight}px + 8rem)`
            : `calc(${labelHeight}px + 4rem)`};
          position: relative;
          overflow: hidden;

          ${MEDIA_QUERIES.desktop} {
            max-height: ${active
              ? `${contentHeight}px`
              : `calc(${labelHeight}px + 6rem)`};
          }
        `
      ]}
    >
      <button
        type="button"
        onClick={() => {
          setActive(!active);
        }}
        css={[tw`w-full relative block border-t text-left`]}
      >
        <Grid node="article">
          <div
            css={[tw`col-span-12 md:col-span-5 pt-8 md:pt-12 pb-8 md:pb-24`]}
          >
            <h3 ref={labelRef} css={[tw`font-head text-b1 md:text-h3-md`]}>
              {label}
            </h3>
          </div>

          <div
            ref={contentRef}
            css={[
              css`
                transition: 0.3s ease opacity, 0.3s ease transform;

                transform: translate3d(0, ${active ? `0` : `-1rem`}, 0);
                opacity: ${active ? 1 : 0};
              `,
              tw`col-span-12 md:col-span-4 md:col-start-7 relative md:pt-12 pb-8 md:pb-24`
            ]}
          >
            <p
              css={[tw`font-body text-b2 md:text-b2-md`]}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </Grid>
      </button>

      <div
        css={[
          tw`w-8 md:w-12 h-8 md:h-12 absolute top-8 right-8 z-10 hidden md:flex items-center justify-center overflow-hidden pointer-events-none rounded-full bg-green-dark`
        ]}
      >
        <span css={[tw`block font-body text-h3 text-green-light`]}>
          {active ? `–` : `+`}
        </span>
      </div>
    </div>
  );
};

const FAQs = ({ questions }) => {
  let i;

  //

  return (
    <>
      {questions?.[0] && (
        <ul>
          {questions.map(({ question, answer }) => (
            <li key={question}>
              <FAQExpander label={question} content={answer} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default FAQs;
