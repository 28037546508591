/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect } from "react";
import tw, { css } from "twin.macro";
import { useApp, useKeyPress, useTheme } from "~hooks";
import { Grid, NewsletterForm } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as Cross } from "~assets/icons/cross.svg";

const NewsletterOverlay = () => {
  // ==========================================================================
  // context / ref / state

  const { newsletterActive, setNewsletterActive } = useApp();
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  const escPressed = useKeyPress(`Escape`);

  // ==========================================================================
  // lifecycle

  useEffect(() => {
    if (escPressed) {
      setNewsletterActive(false);
    }
  }, [escPressed]);

  // ==========================================================================
  // render

  const transitionDuration = `0.6s`;

  //

  return (
    <div
      css={[
        css`
          ${colourTransitionCSS}, ${transitionDuration} cubic-bezier(0.215, 0.61, 0.355, 1) opacity, ${transitionDuration} cubic-bezier(0.215, 0.61, 0.355, 1) transform;

          opacity: ${newsletterActive ? `1` : `0`};
          pointer-events: ${newsletterActive ? `auto` : `none`};
          transform: scale(${newsletterActive ? `1` : `1.025`});

          padding-top: 56px;
          background: ${colours.background};

          ${MEDIA_QUERIES?.desktop} {
            padding-top: 90px;
          }
        `,
        tw`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-30 flex items-center justify-center`
      ]}
    >
      <div
        css={[tw`w-screen h-screen absolute top-0 right-0 bottom-0 left-0`]}
        role="presentation"
        onClick={() => setNewsletterActive(false)}
      />

      <Grid
        _css={[
          css`
            pointer-events: none;
            z-index: 10;
          `
        ]}
      >
        <article
          css={[
            tw`col-span-12 md:col-span-6 md:col-start-4 relative flex items-center justify-center z-20`
          ]}
        >
          <div
            css={[
              css`
                display: none;

                ${MEDIA_QUERIES?.desktop} {
                  display: block;
                }
              `,
              tw`w-16 h-16 absolute -top-2 right-0 z-10`
            ]}
          >
            <button
              type="button"
              css={[tw`w-full h-full relative block pointer-events-auto`]}
              onClick={() => setNewsletterActive(false)}
            >
              <div
                css={[
                  tw`w-full h-full relative flex items-center justify-center p-4`
                ]}
              >
                <Cross
                  css={css`
                    width: 100%;
                  `}
                  fill={colours.foreground}
                />
              </div>
            </button>
          </div>

          <NewsletterForm inOverlay prefix="2" />
        </article>
      </Grid>
    </div>
  );
};

export default NewsletterOverlay;
