/* eslint-disable react/prop-types */
// importSource //

import React from "react";

import tw, { css } from "twin.macro";
import { useApp, useTheme } from "~hooks";
import { LoginForm } from "~components";

const LoginOverlay = () => {
  const { loginActive } = useApp();
  const { colours } = useTheme();

  //

  return (
    <div
      css={[
        css`
          transition: 0.15s ease color;

          opacity: ${loginActive ? `1` : `0`};
          pointer-events: ${loginActive ? `auto` : `none`};
          color: ${colours.foreground};
        `,
        tw`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-30`
      ]}
    >
      <LoginForm />
    </div>
  );
};

export default LoginOverlay;
